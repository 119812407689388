exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avantiq-js": () => import("./../../../src/pages/avantiq.js" /* webpackChunkName: "component---src-pages-avantiq-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case_studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-convy-js": () => import("./../../../src/pages/convy.js" /* webpackChunkName: "component---src-pages-convy-js" */),
  "component---src-pages-gallery-react-js": () => import("./../../../src/pages/GalleryReact.js" /* webpackChunkName: "component---src-pages-gallery-react-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ipaas-js": () => import("./../../../src/pages/ipaas.js" /* webpackChunkName: "component---src-pages-ipaas-js" */),
  "component---src-pages-kelisto-js": () => import("./../../../src/pages/kelisto.js" /* webpackChunkName: "component---src-pages-kelisto-js" */),
  "component---src-pages-meka-js": () => import("./../../../src/pages/meka.js" /* webpackChunkName: "component---src-pages-meka-js" */),
  "component---src-pages-sharemos-js": () => import("./../../../src/pages/sharemos.js" /* webpackChunkName: "component---src-pages-sharemos-js" */),
  "component---src-pages-subtitlingsaas-js": () => import("./../../../src/pages/subtitlingsaas.js" /* webpackChunkName: "component---src-pages-subtitlingsaas-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-video-with-popups-js": () => import("./../../../src/pages/VideoWithPopups.js" /* webpackChunkName: "component---src-pages-video-with-popups-js" */)
}

